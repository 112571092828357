
main{
    background-image: url('../../../assets/images/members.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--secondary);
    background-blend-mode: multiply; 
    display: flex;
    justify-content: center; 
    align-items: center;
  }
  
main .content-container{
    height: 99%;
    width: 98vw;
    border-radius: 0.25em;
    background-color: rgba(0, 93, 170, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (min-width: 1200px){
    main .content-container{
        width: 75vw;
        height: 85%;
    }
}

@media screen and (min-width: 1400px){
    main .content-container{
        width: 66.7vw;
        
    }
}


  