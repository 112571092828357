.side-buttons{
    font-size: 1.125rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgb(0 168 80 / var(--tw-text-opacity));
}

.add-chest-wrapper{ 
    cursor: pointer;
    width: 20%;
    margin: 0 auto;
    padding: 0;
}

.add-chest-item{
    background-color: var(--primary);
    border-radius: 0.25em;
    text-align: center; 
    padding: 0.5em 1.5em;
    margin: 0;
}

.add-calendar-wrapper{ 
    cursor: pointer;
    margin: 0 auto;
    padding: 0;
}

.add-calendar-item{
    background-color: var(--primary);
    border-radius: 0.25em;
    text-align: center; 
    padding: 0.5em 1.5em;
    margin: 0;
}