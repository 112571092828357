.best-practices-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.best-practices-btn{
    background: var(--primary);
    border: none;
    padding: 0.5em 1em;
    color: white;
    border-radius: 0.5em;
    align-self: flex-end;
    margin: 0.5em 0.5em 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25em;
}


