.calendar-container{
    height: calc(100% - 0.25em);
    width: calc(100% - 0.25em);
    display: flex;
    flex-direction: column;
}

.calendar{
    height: 100%;
    background: white;
    font-family: var(--font);
    font-weight: 300;
    border-radius: 0.25em;
}

.rbc-toolbar{
    background-color: var(--primary);
    margin: 0;
    padding: 0.25em;
    color: white;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
}

.rbc-toolbar-label{
    font-size: 1.25em;
    font-weight: 400;
}


.rbc-toolbar .rbc-btn-group button{
    color: inherit;
    border: 1px solid white;
    background: var(--state-4);
}

.rbc-toolbar .rbc-btn-group button{
    margin: 0 0.1em;
    color: white;
    border: 1px solid white;
}

.rbc-toolbar .rbc-btn-group:first-of-type button:hover,
.rbc-toolbar .rbc-btn-group button:focus{
    color: white;
    border: 1px solid white;
}


.rbc-toolbar .rbc-btn-group:first-of-type button:hover,
.rbc-toolbar .rbc-btn-group:first-of-type button:focus{
    background: var(--state-3);
    
}

.rbc-toolbar .rbc-btn-group:last-of-type .rbc-active:focus{
    background: var(--secondary);
}

.rbc-toolbar .rbc-btn-group:last-of-type button:hover{
    background: var(--state-3);
}

.rbc-toolbar .rbc-btn-group:last-of-type .rbc-active{
    background: var(--secondary);
}

.rbc-header{
    color: white;
    background: var(--other-blue);
    font-weight: 300;
}

.rbc-time-view{
    border-bottom-left-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}