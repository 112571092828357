.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(17, 17, 17, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.modal{
    width: 97.5vw;
    max-width: 400px;
    max-height: 99vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--other-blue);
    border: 1px solid rgba(255,255,255, 0.25);
    border-radius: 0.25em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal .modal-close{
    font-size: 1.5em;
    line-height: 1;
    border: 1px solid white;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
}

.modal .loader{
    width: 100%;
    height: 100%;
}

.modal form{
    width: 95%;
    max-height: 85%;
    padding: 0 1em;
    overflow-Y: auto;
    overflow-X: hidden;
}

.modal form .form-field{
    margin-bottom: 1em;
    padding: 0;
}


.modal form .form-field label{
    margin-bottom: 0;
    color: white;
    font-family: var(--font);
}

.modal form #allDayCheck{
    margin-bottom: 0.5em;
}

.modal form #allDayCheck label{
    margin-left: 0.25em;
}

.modal form #iu-select label:first-of-type,
.modal form #iu-select label:nth-of-type(2){
    margin-right: 0.25em;
}

.modal form .form-field .error-msg{
    margin-bottom: 0;
    background: var(--state-warning);
    color: white;
    padding-left: 0.25em;
    font-size: 0.9em;
    border-radius: 0.1em;
}

.modal form button[type="submit"]{
    padding: 0.35em 0.7em;
    background: var(--primary);
    font-family: var(--font);
    text-transform: uppercase;
    color: white;
    border-radius: 0.25em;
    display: block;
    margin-left: auto;
}

.modal form #downloadable{
    margin-top: 1em;
}

.modal form #downloadable #downloadable-item{
    display: flex; 
}

.modal form #downloadable #downloadable-item a{
    color: white;
}

.modal form #downloadable #downloadable-item p{
    margin: 0;
    color: white;
    font-family: var(--font);
}

.modal form #btn-group{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2em;
}

.modal form #btn-group button{
    display: unset;
    margin: 0;
}

.modal form #btn-group button:not([type="submit"]){
    padding: 0.35em 0.7em;
    background: var(--state-warning);
    font-family: var(--font);
    text-transform: uppercase;
    color: white;
    border-radius: 0.25em;
}


.modal form .form-field #upload-item{
    color: white;
    display: flex;
    margin-top: 1em;
}

.modal form .form-field #upload-item p{
    margin: 0;
}


@media screen and (min-width: 767px){
    .modal{
        max-width: 500px;
        height: 60vh;
    }
}