.chest-item-container{
    border: 1px solid rgba(255,255,255,0.35);
    color: white;
}

.chest-item-header{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--secondary);
}

.chest-item-header p{
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.5em;
}

.chest-item-header svg{
    margin-left: auto;
    margin-right: 0.5em;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.chest-item-body{
    background: var(--other-blue);
    min-height: 240px;
    padding: 0.75em;
    display: flex;
    flex-direction: column;
}

.chest-item-body p{
    margin: 0;
}

.chest-item-body a{
    background: var(--primary);
    align-self: flex-end;
    margin-top: auto;
    padding: 0.5em 1em;
    text-decoration: none;
    color: white;
    border-radius: 0.25em;
    cursor: pointer;
}
