.tool-chest-items-container{
    overflow-y: auto;
    margin: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}


@media screen and (min-width: 767px){
    .tool-chest-items-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 0.5em;
    }
}

@media screen and (min-width: 992px){
    .tool-chest-items-container{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1400px){
    .tool-chest-items-container{
        grid-template-columns: repeat(4, 1fr);
    }
}