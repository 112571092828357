.add-tool-chest-item-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1em;
}

.add-tool-chest-item-container label{
    color: white;
    font-family: var(--font);
    padding-bottom: 0;
}

.add-tool-chest-item-container input, .add-tool-chest-item-container textarea{
    font-family: var(--font);
    padding: 0.5em;
    margin: 0 0 1em 0;
    border-radius: 0.25em;
}

.add-tool-chest-item-container .warning{
    margin-top: -1em;
    color: white;
    background-color: var(--state-warning);
    border-bottom-left-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    padding-left: 0.5em;
}


.add-tool-chest-item-container input:focus, .add-tool-chest-item-container textarea:focus{
    outline: 1px solid transparent;
}

.add-tool-chest-item-container textarea{
    height: 200px;
    resize: none;
}

.add-tool-chest-item-btn-group{
    display: flex;
}

.add-tool-chest-item-btn-group button{
    padding: 0.5em 1em;
    color: white;
    text-transform: uppercase;
    border-radius: 0.25em;
}

#save-tool-chest-item{
    background-color: var(--primary);
    margin-right: 1em;
}

#cancel-tool-chest-item{
    background-color: var(--state-warning);
}

