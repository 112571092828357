#bp-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    gap: 1em;
}

#editToggle{
    padding: 0.35em 0.7em;
    background: white;
    color: var(--other-blue);
    border: 1px solid var(--other-blue);
    border-radius: 0.25em;
    margin-bottom: 0.25em;
    font-size: 0.8em;
}

.ck-editor__editable_inline{
    min-height: 150px;
    color: black;
}

.tags-list-read{
    display: flex;
    list-style: none;
    padding: 0;
    gap: 0.25em;
    justify-content: flex-start;
}

.tags-list-read li{
    background: var(--state-warning);
    padding: 0.25em;
    border-radius: 0.25em;
}

.tag-list{
    display: flex;
    gap: 0.25em;
}

.tag-list div{
    background: var(--state-warning);
    padding: 0.25em;
    border-radius: 0.25em;
}

.tag-list div button{
    margin-left: 0.25em;
}


@media (min-width: 768px){
    #bp-form{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-template-areas: 
                             '. . toggle'
                             'title title .'
                             'description description .'
                             'body body body'
                             'body body body'
                             'supplements tags tools'
                             'supplements tags tools'
                             '. . submit';
    }

    #title-field{
        grid-area: title;
    }

    /*#category-field{
        grid-area: category;
    }*/

    #description-field{
        grid-area: description;
    }

    #content-field{
        grid-area: body;
        
    }

    #supplementary-field{
        grid-area: supplements;
        
    }

    #tag-field{
        grid-area: tags;
    }

    #tools-field{
        grid-area: tools;
    }

    #submit-btn{
        grid-area: submit;
        align-self: center;
        justify-self: end;
    }

    #editToggle{
        grid-area: toggle;
        justify-self: end;
        margin-bottom: 0;
    }
}



#submit-btn{
    background: var(--primary);
    display: flex;
    justify-content: center;
    padding: 0.5em 1em;
    border-radius: 0.25em;
}

#submit-btn svg{
    margin-top: -0.1em;
    margin-right: 0.25em;
}

#submit-btn span{
    text-transform: uppercase;
}

.bp-form-field{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.bp-form-field label{
    font-size: 0.8em;
    text-transform: uppercase;
    font-family: var(--font);
}

.bp-form-field input{
    color: var(--secondary);
    padding: 0.25em 0 0.25em 0.25em; 
}

.bp-form-field input:focus{
    outline: 1px solid var(--primary);
}

.bp-form-field input:read-only{
    background: #BBB;
}

input[type="file"]{
    padding: 0.125em;
}

input[type="file"]::file-selector-button{
    border: none;
    background: var(--state-warning);
    color: white;
    padding: 0.15em 0.3em;
    cursor: pointer;
}

/*Style in read mode */
#title-author{
    font-family: var(--font);
}

#title-author h3{
   font-size: 2em;
   line-height: 1;
   margin: 0; 
   text-decoration: underline;
   text-decoration-color: var(--primary);
   text-underline-offset: 0.125em;
}

#title-author small{
    font-size: 0.8em;
}

.tool-chest-select{
    padding: 0.35em;
    color: black;
    outline: 1px solid transparent;
}

#tool-link-url{
    color: white;
    cursor: pointer;
    margin-top: 0.25em;
}

.error-msg{
    background-color: var(--state-warning);
    color: white;
    padding-left: 0.25em;
}

.file-list{
    display: flex;
    gap: 0.25em;
}

.file-list-item{
    display: inline-flex;
    gap: 0.25em;
    padding: 0.25em;
    justify-content: center;
    align-items: center;
    background: var(--state-warning);
    cursor: pointer;
}

.file-list-item a{
    text-decoration: underline;
    text-decoration-color: white;
}

.file-list-item span{
    cursor: pointer;
    font-size: 1.25em;
    align-self: start;
    line-height: 1;
}