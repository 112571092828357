.breadcrumb-list{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    padding: 0.25em 1em 0.25em 0;
    background: var(--primary);
    margin: 0;
}

.breadcrumb-item{
    cursor: pointer;
}

.breadcrumb-link{
    color: white;
}

.breadcrumb-link:hover{
    color: white;
}