@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");


:root {
  --primary: #00a94f;
  --secondary: #005daa;
  --state-warning: #be413b;
  --state-3: #3f689a;
  --state-4: #6290b7;
  --other-blue: #004783;
  --other-black: #000000;
  --other-white: #eeeeee;
  --font: 'Roboto', Arial, Helvetica, sans-serif;
}

p, small, input{
  font-family: var(--font);
}

/* log-in screens specific css */
.form-parent{
  background-image: url('../assets/images/members.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--secondary);
  background-blend-mode: multiply;
}

.form-container{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}


.login-form-container{
  background: rgba(0, 71, 131, 0.25);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.25); 
  border-radius: 0.25em;
  width: 97.5%;
  padding: 1em 0.5em;
}

.login-form-container .logo{
  margin: 1em 0;
}


.login-form-header{
  color: white;
  font-size: 1.1em;
  text-align: center;
}

.helper-text{
  color: white;
  font-size: 0.8em;
  display: inline-block;
}

.login-form-links{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkText:nth-of-type(1){
  margin-bottom: 0.5em;
}

.forgot-password-sign-in{
  color: white;
  margin-top: 1em;
}

.btn-green{
  background: var(--primary);
  color: white;
}

.login-form-btn{
  display: block;
  margin: 0 auto;
}

.login-form-btn:hover{
  color: white;
  background: #007637;
}

.login-input-error, .authentication-code-error{
  color: red;
  display: block;
}

.forgot-pswd-sign-in, .register-sign-in{
  color: white;
  font-size: 0.8em;
  display: flex;
  justify-content: flex-end;
}

.forgot-pswd-sign-in:hover, .register-sign-in:hover{
  color: white;
}

.register-form{
  margin: 0;
  height: 50vh;
  overflow-y: scroll;
  padding: 0 0.25em;
}

.register-form input{
  margin-bottom: 0.5em;
}

.register-required-input{
  display: flex;
  flex-direction: column;
}

.register-required-input small{
  color: red;
  display: block;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
}

.register-phone-disclaimer{
  display: flex;
  color: white;
  font-size: 0.8em;
}

.register-phone-disclaimer input{
  align-self: flex-start;
  margin: 0.25em 0.25em 0 0;
}

.register-iu-check{
  display: flex;
  color: white;
  font-size: 0.8em;
  margin-bottom: 0.25em;
}

.register-iu-check input{
  margin: 0.25em 0.25em 0 0;
}

.register-iu-check p{
  margin: 0.25em 0 0 0;
}

.register-affiliate-state{
  margin-bottom: 0.5em;
}

.register-btn-group{
  display: flex;
  justify-content: space-evenly;
  margin-top: 1em;
}

.register-form-clear{
  background: var(--state-warning);
  color: white;
}

.register-form-clear:hover, .register-form-submit:hover{
  color: white;
}

.register-form-submit:hover{
  background: #007637;
}

.register-form-clear:hover{
  background: #b30000;
}

.authentication-code-input-group{
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

.disabled-btn{
  background: var(--state-4);
  color: white;
  cursor: not-allowed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}


@media screen and (min-width: 375px){
  .login-form-links{
    flex-direction: row;
    justify-content: space-around;
  }

  .linkText:nth-of-type(1){
    margin-bottom: 0;
  }
}

@media screen and (min-width: 576px){
  .form-container{
    width: 500px;
  }
  .login-form-container{
    padding: 1em 2em;
  }
  .register-form{
    overflow-Y: unset;
    padding: 0;
    height: 100%;
  }
}

/* All logged in pages */
.custom-navbar{
  background: var(--other-blue);
  padding: 0;
}

.custom-navbar .container-fluid{
  background: inherit;
  margin: 0;
  padding: 0 1em 0 0;
  display: grid;
  grid-template-columns: 90% 10%;
  grid-template-rows: auto;
  grid-template-areas: 'brand toggler'
                       'nav nav';
}

@media screen and (min-width: 992px){
  .custom-navbar .container-fluid{
    grid-template-columns: 1fr 4fr;
    grid-template-areas: 'brand nav';
  }

  #responsive-navbar-nav{
    justify-self: end;
  }
}

.afscme-logo{
  grid-area: brand;
  max-height: 5em;
  margin: 0;
}

.navbar-toggler{
  border: none;
  grid-area: toggler
}

#responsive-navbar-nav{
  grid-area: nav;
  padding: 0 0 0 2em;
}


.navbar-toggler .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");  
}

.navbar-toggler:focus{
  outline: none;
  box-shadow: none;
}

#responsive-navbar-nav .navbar-nav a{
  color: white;
  font-weight: 500;
}

/* Calendar specific */

.rbc-day-slot .rbc-timeslot-group{
  pointer-events: auto;
}

/* Tool Chest page */
.tool-chest-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tool-chest-add-btn-container{
  align-self: flex-end;
  margin: 0.5em 0.25em 0.5em 0;
}

.tool-chest-add-btn{
  background: var(--primary);
  color: white;
  padding: 0.5em 1em;
  border-radius: 0.25em;
}

.tool-chest-items{
  width: 100%;
  overflow-y: auto;
  list-style: none;
  padding: 0.25em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 0;
}

.tool-chest-item{
  background-color: var(--other-blue);
  border: 1px solid rgba(0, 169, 79, 0.75);
  border-radius: 0.1em;
  color: white;
  font-family: var(--font);
  display: flex;
  flex-direction: column;
}

.tool-chest-item-header{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--secondary);
}

.tool-chest-item-header p{
  margin: 0;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.5em;
}

.tool-chest-item-header span{
  margin-left: auto;
  margin-right: 0.5em;
  position: absolute;
  right: 0.5em;
}

.tool-chest-item-body{
  padding: 1em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.tool-chest-item-body a{
  text-decoration: none;
  color: white;
  background-color: var(--primary);
  padding: 0.5em 1em;
  border-radius: 0.25em;
  align-self: flex-end;
  margin-top: auto;
}

@media screen and (min-width: 576px){
  .tool-chest-items{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 0.5em;
  }
}

@media screen and (min-width: 992px){
  .tool-chest-items{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1400px){
  .tool-chest-items{
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}








