.categories-container{
    margin: 0.5em;
    flex: 1;
    overflow-Y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    padding: 0 0.25em;
    row-gap: 0.5em;
}

.category-bar{
    color: white;
    border-radius: 0.1em;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: var(--other-blue);
    padding: 0.5em;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 992px){
    .category-bar{
        display: grid;
        grid-template-columns: 60% 1fr;
        grid-template-rows: auto;
        grid-template-areas: 'title btns'
                             'description btns'
                             'articles articles'
                             'article article';
        column-gap: 0.5em;
    }

    #category-title{
        grid-area: title;
    }

    #category-description{
        grid-area: description;

    }

    #category-btn-group{
        grid-area: btns;
        justify-self: end;
    }

    .category-bar #category-btn-group .btn{
        margin: 0;
    }

    .articles-list{
        grid-area: articles;
    }
}

.category-bar p{
    margin: 0;
    font-family: var(--font);
}

.category-bar #category-title{
    text-transform: uppercase;
    font-size: 1.25em;
    align-self: center;
}

.category-bar #category-description{
    font-weight: 300;
    font-size: 0.8em;
}

.category-bar #category-btn-group{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
}

.category-bar #category-btn-group .btn{
    padding: 0.35em 0.7em;
    color: white;
    cursor: pointer;
    display: flex;
    margin: 0 0.5em;
    align-items: center;
    justify-content: center;
}

@media (max-width: 410px){
    .category-bar #category-btn-group .btn svg{
        display: none;
    }
}

.category-bar #category-btn-group .btn-delete{
    background: var(--state-warning);
}

.category-bar #category-btn-group .btn-more{
    background: var(--primary); 
}


.articles-list{
    display: flex;
    flex-direction: column;
    padding: 1em 0;
    row-gap: 1em;
    background: var(--secondary);
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.articles-list ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    padding: 0 0.5em;  
    max-height: calc(100% - 1em);
    overflow-y: auto;
}

@media (min-width:992px){
    .articles-list ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.articles-list ul li{
    display: flex;
    column-gap: 0.25em; 
}

.articles-list ul li button{
   text-decoration: underline;
   font-size: 1.1em;
}

.articles-list ul li span{
    vertical-align: super;
    font-size: 0.67em;
    color: var(--primary);
    font-weight: bold;
    text-transform: uppercase;
}

.articles-list #no-articles{
    padding: 0 0 0 0.5em;
}

.articles-list #add-new-article{
    align-self: flex-start;
    background: white;
    color: var(--other-blue);
    margin-left: 0.5em;
    padding: 0.35em 0.7em;
    border-radius: 0.25em;
    display: flex;
    column-gap: 0.25em;
    cursor: pointer;
}

.article-viewer{
    grid-area: article;
    background: var(--secondary);
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.article-viewer .article-navigation{
    display: flex;
    justify-content: space-evenly;
    padding: 0.5em 0 1em 0;
}

.article-viewer .article-navigation button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    padding: 0.25em 0.5em;
    color: white;
    cursor: pointer;
    background: var(--state-4);
    color: white;
}

@media (max-width: 576px){
    .article-viewer .article-navigation button span{
        display: none;
    }

}

.article-viewer .article-header{
    background: var(--state-3);
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
}

.article-viewer .article-header p{
    border: 1px solid red;
    font-size: 1.1em;
    text-transform: uppercase;
}

.article-viewer .article-main{
    padding: 0.5em;
}